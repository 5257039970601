.section.debug {

	background-color: $brand-black;
	color: $brand-white;
	text-align: left;

	align-items: flex-start;
	justify-content: flex-start;

	.inner {
		width: 80rem;
		margin: auto;
	}

	.controller-wrapper {

		border: solid 1px $brand-white;
		width: 100%;
		padding: 2rem 2rem 3rem 2rem;
		margin-bottom: 4rem;

		.question-wrapper {
			margin-bottom: 6rem;
		}

		.location-wrapper,
		.backdrop-wrapper {
			margin-bottom: 6rem;
		}

		.scene-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 1rem;
		}

		.backdrop-label,
		.question-label,
		.scenes-label,
		.locations-label {
			font-size: 2rem;
			font-weight: 700;
			display: block;
			margin-top: 1rem;
			margin-bottom: 2.0rem;
			line-height: 1.5em;
		}

		.scenes-label {
			margin-bottom: 0.25rem;
		}

		.scene-label {
			font-size: 1.4rem;
			font-weight: 700;
			display: block;
			margin-top: 1rem;
			margin-bottom: 1.0rem;
			line-height: 1.5em;
		}

		.scenes-column {
			width: 25%;
		}


		.qid {
			flex-grow: 0;
			font-size: 0.8em;
		}

		.backdrop-option,
		.question-selector,
		.scene-option,
		.location-option {
			font-size: 1.4em;
			font-weight: 500;
			display: block;
			margin-bottom: 0.75em;
			cursor: pointer;

			opacity: 0.5;

		}

		.backdrop-option,
		.question-selector,
		.scene-option,
		.location-option {
			&:hover {
				color: $brand-green;
				opacity: 0.8;
			}

			&.selected {
				color: $brand-green;
				opacity: 1.0;
			}
		}

		.question-selector {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

	}

	.row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.my-location-label {
		font-size: 2rem;
	}

	.label-text {
		font-size: 1.5em;
	}
	
	.label {
		margin-top: 6rem;
		margin-bottom: 2rem;
		width: 100%;
		border-top: solid 1px $brand-white;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.location-select {
		font-size: 1.5em;
		padding: 0.2em 0.6em;
		background-color: $brand-black;
		color: $brand-white;
	}

	.submit-as-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.location-select-label {
		margin-right: 1rem;
		font-size: 1.1rem;
		opacity: 0.5;
	}

	.question-module {

		border: solid 1px $brand-white;
		width: 100%;
		padding: 2rem 2rem 3rem 2rem;
		margin-bottom: 4rem;
		display: none;

		&.visible {
			display: block;
		}

		.columns-wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}

		.answer-column {
			// width: 70%;
			flex-grow: 0;
			// overflow: hidden;
		}

		.data-column {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: flex-start;
			// width: 30%;
			flex-grow: 0;
		}

		.location-wrap {
			margin-left: 2rem;
			text-align: center;
		}


		.question,
		.location-label { 
			font-size: 2rem;
			font-weight: 700;
			display: block;
			margin-top: 1rem;
			margin-bottom: 2.5rem;
			line-height: 1.5em;
			// word-wrap: none;
			// white-space: nowrap;
		}

		.view-option,
		.response,
		.location-value {
			font-size: 1.4em;
			font-weight: 500;
			display: block;
			margin-bottom: 0.75em;
			cursor: pointer;

			opacity: 0.5;

		}

		.response {
			&:hover {
				color: $brand-green;
				opacity: 0.8;
			}

			&.selected {
				color: $brand-green;
				opacity: 1.0;
			}
		}

		.range-slider {
			width: 50%;
			display: block;
			margin: 1rem auto 1rem;
		}

		.range-label {
			width: 100%;
			text-align: center;
			font-size: 1.4em;
			font-weight: 500;
		}

		.submit {
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			font-size: 1.2em;
			padding: 1em 3em;
			max-width: 15rem;
			text-align: center;
			margin: 4rem auto 0;
			opacity: 0.8;

			cursor: not-allowed;
			outline: solid 1px $brand-white;
			color: $brand-white;
			background-color: unset;
			pointer-events: none;
			touch-action: none;

			&.ready {
				background-color: $brand-white;
				color: $brand-black;
				outline: solid 1px $brand-black;
				cursor: pointer;
				pointer-events: initial;
				touch-action: initial;

				&:hover {
					opacity: 1.0;
				}
			}
		}

	}










}


@media screen and ( min-width: $breakpoint-1 ) {

	.section.debug {

	}

}


@media screen and ( min-width: $breakpoint-2 ) {

	.section.debug {

	}

}


@media screen and ( min-width: $breakpoint-3 ) {

	.section.debug {

	}

}


@media screen and ( min-width: $breakpoint-4 ) {

	.section.debug {

	}

}


@media screen and ( min-width: $breakpoint-5 ) {

	.section.debug {

	}

}


@media screen and ( min-width: $breakpoint-6 ) {

	.section.debug {

	}

}


@media screen and ( min-width: $breakpoint-7 ) {

	.section.debug {

	}

}
