body {
	z-index: 0;
}

.stack-wrap {
	z-index: 1;
}

.container {
	z-index: 2;
}



.svg-wrap.bg {
	z-index: 1;
	// display: none;
}

.world-wrap {
	z-index: 2;
	// display: none;
}

.svg-wrap.main {
	z-index: 3;
	// display: none;
}


.section.poll .question-module.range {
	.bar-label {
		z-index: 9;
	}
}