.texta {
	
	font-family: 'texta';
	font-weight: 500;
	letter-spacing: 0.022em;
	line-height: 1.04em;

	h1 {
		font-size: 3em;
		line-height: 3em;
	}

	.link {
		cursor: pointer;
		text-decoration: underline;
		color: $brand-blue;

		font-size: 1.5em;
		line-height: 1.5em;
	}

}

.optimistic {
	
	font-family: 'optimistic';
	font-weight: 500;
	letter-spacing: 0.022em;
	line-height: 1.35em;

	h1 {
		font-size: 3em;
		line-height: 3em;
	}

	.link {
		cursor: pointer;
		text-decoration: underline;
		color: $brand-blue;

		font-size: 1.5em;
		line-height: 1.5em;
	}

}


@media screen and ( min-width: $breakpoint-1 ) {

	.optimistic {
	
		 h1 {
			font-size: 4em;
		}

		.link {
			font-size: 2.0em;
		}

	}

}

@media screen and ( min-width: $breakpoint-2 ) {

	.optimistic {
	
		.link {
			// font-size: 2.0em;
		}

	}

}

@media screen and ( min-width: $breakpoint-3 ) {

	.optimistic {
	
		.link {
			// font-size: 2.0em;
		}

	}

}

@media screen and ( min-width: $breakpoint-4 ) {

	.optimistic {
	
		.link {
			// font-size: 2.0em;
		}

	}

}

@media screen and ( min-width: $breakpoint-5 ) {

	.optimistic {
	
		.link {
			// font-size: 2.0em;
		}

	}

}

@media screen and ( min-width: $breakpoint-6 ) {

	.optimistic {
	
		.link {
			// font-size: 2.0em;
		}

	}

}

@media screen and ( min-width: $breakpoint-7 ) {

	.optimistic {
	
		.link {
			// font-size: 2.0em;
		}

	}

}