// - - - Optimistic - - - //

/*

BOLD         -   700
MEDIUM       -   500
LIGHT        -   300

*/



@font-face {
	font-family: 'optimistic';
	src: url('/assets/fonts/optimistic/Optimistic_Display_Light.eot');
	src: url('/assets/fonts/optimistic/Optimistic_Display_Light.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/optimistic/Optimistic_Display_Light.woff2') format('woff2'), url('/assets/fonts/optimistic/Optimistic_Display_Light.woff') format('woff'), url('/assets/fonts/optimistic/Optimistic_Display_Light.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'optimistic';
	src: url('/assets/fonts/optimistic/Optimistic_Display_Medium.eot');
	src: url('/assets/fonts/optimistic/Optimistic_Display_Medium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/optimistic/Optimistic_Display_Medium.woff2') format('woff2'), url('/assets/fonts/optimistic/Optimistic_Display_Medium.woff') format('woff'), url('/assets/fonts/optimistic/Optimistic_Display_Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'optimistic';
	src: url('/assets/fonts/optimistic/Optimistic_Display_Bold.eot');
	src: url('/assets/fonts/optimistic/Optimistic_Display_Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/optimistic/Optimistic_Display_Bold.woff2') format('woff2'), url('/assets/fonts/optimistic/Optimistic_Display_Bold.woff') format('woff'), url('/assets/fonts/optimistic/Optimistic_Display_Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
