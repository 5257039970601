// FONT

html {
  // 1rem = 10px
  font-size: 10px;		// 1rem = 10px
}

body {
	@extend .optimistic;
	line-height: 1.3;
	background-color: $brand-white;
	color: $brand-black;
	position: relative;

	user-select: none;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
}

input,
textarea,
button,
select,
a,
div {
	-webkit-tap-highlight-color: transparent;
}