body {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@extend .optimistic;
	color: $brand-white;
	line-height: 1.15em;
	user-zoom: fixed;
	// touch-action: manipulation;
}

html {
	-webkit-text-size-adjust: none;
	// touch-action: pan-y; /*prevent user scaling*/
}

.stack-wrap {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	// -webkit-backface-visibility: visible;
	transform: translate3d( 0,0,0 );

	// background-color: rgba( 255,255,0, 0.5);
}

.svg-wrap, .world-wrap {
	// width: 100%;
	// height: 100vh;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	// -webkit-backface-visibility: visible;
	// transform: translate3d( 0,0,0 );

}

.container {
	width: 100%;
	position: relative;
	min-height: calc( 100vh - 4rem );
}

.container-inner {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	top: 0;
	left: 0;

	position: relative;
	overflow-x: hidden;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	-webkit-overflow-scrolling: touch;
}

.section {
	width: 100%;
	min-height: calc(100vh);
	min-height: calc(var(--vh, 1vh) * 100);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	// overflow-y: scroll;
}

.no-js-warning,
.no-ie-warning {
	display: none;
	position: absolute;

	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	top: 0;
	left: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	font-size: 3em;
	text-align: center;
	line-height: 1.5em;
	padding: 8rem;

}

.no-landscape-warning {
	display: none;
	position: absolute;

	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	top: 0;
	left: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);

	font-size: 2em;
	text-align: center;
	line-height: 1.5em;
	padding: 8rem;

	background-color: #1C2B33;

	z-index: 99;

}

.no-js {
	.stack-wrap,
	.svg-wrap.bg,
	.world-wrap,
	.svg-wrap.main,
	.container,
	.no-ie-warning {
		display: none;
	}

	.no-js-warning {
		display: block;
	}

}

.no-ie {
	.stack-wrap,
	.svg-wrap.bg,
	.world-wrap,
	.svg-wrap.main,
	.container,
	.no-js-warning {
		display: none;
	}

	.no-ie-warning {
		display: block;
	}

}

.no-landscape {
	.no-landscape-warning {
		display: flex;
	}
}


@media screen and ( min-width: $breakpoint-1 ) {


}


@media screen and ( min-width: $breakpoint-2 ) {


}

@media screen and ( min-width: $breakpoint-3 ) {


}

@media screen and ( min-width: $breakpoint-4 ) {


}

@media screen and ( min-width: $breakpoint-5 ) {


}

@media screen and ( min-width: $breakpoint-6 ) {


}

@media screen and ( min-width: $breakpoint-7 ) {


}