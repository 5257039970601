$poll-col-small-max: 36rem;
$poll-col-large-max: 64rem;

.section.poll {

	padding: 2rem;
	// padding-bottom: 6rem;

	// padding-top: 4rem;
	// padding-bottom: 4rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	pointer-events: none;
	touch-action: none;

	&.interactive {
		pointer-events: initial;
		touch-action: initial;
	}



	&>.inner {
		position: relative;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		flex-grow: 1;
	}



	// - - - TYPOGRAPHY extendable classes

	.col-small {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.col-large {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.basic-type {
		font-size: 1.05em;
	}




	// - - - NAV bar

	.nav-bar-wrap {
		position: fixed;
		top: 0;
		width: 100%;
		left: 0;
		padding: 2rem;

		svg {
			fill: $brand-white;
			margin: 0;
		}
	}

	.nav-bar-inner {
		@extend .col-small;
		height: 3rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}

	.button-back,
	.button-next {
		display: flex;
		flex-direction: row;
		align-items: center;

		border: 1px solid $brand-white;
		padding: 0.8rem;
		padding-right: 0.8rem;
		padding-left: 0.8rem;

		overflow: hidden;

		width: 6.75em;

		cursor: pointer;

		transition: opacity 0.25s linear;

		opacity: 0.2;

		position: absolute;

	}

	&.interactive  {

		.button-back,
		.button-next {

			opacity: 1.0;	

		}
		
	}


	.button-back {
		justify-content: flex-start;
		border-color: rgba( 255,255,255,0.0 );
		padding-left: 0;
		left: 0;

		transition: border-color 0.2s linear, color 0.2s linear, width 0.2s ease-in-out, padding-right 0.25s linear, opacity 0.25s linear;
		// padding-left: 0;
	}
	
	.button-next {
		justify-content: flex-end;	
		right: 0;
		// padding-right: 0;
		color: rgba( 255,255,255, 1 );

		transition: border-color 0.2s linear, color 0.2s linear, width 0.2s ease-in-out, padding-right 0.25s linear, opacity 0.25s linear;

	}

	.button-back.minimal,
	.button-next.minimal {
		border-color: rgba( 255,255,255,0.0 );
		color: rgba( 255,255,255, 0.0 );
		width: 3rem;
	}

	.button-back.minimal {
		padding-left: 0;
	}

	.button-next.minimal {
		padding-right: 0;
	}

	.button-back .arrow svg {
		transform: rotate( 180deg );
	}


	.button-back .label {
		margin-left: 1rem;
		margin-right: 0.2rem;
	}


	.button-next .label {
		margin-right: 1rem;
		margin-left: 0.2rem;
	}

	.arrow {
		min-width: 1.2rem;
		min-height: 1.2rem;
		width: 1.2rem;
		height: 1.2rem;
	}

	.button-back .label,
	.button-next .label {
		@extend .basic-type;
		font-weight: 500;
		margin-bottom: 0.2rem;
	}

	.progress-wrap {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 3rem;
		width: 100%;
		padding: 1rem 8rem;
	}

	.progress-module {

		max-width: 2.5rem;
		flex-grow: 1;
		margin-top: 1px;
		padding: 1rem 0;
		transform: scale( 1.0 );
		transform-origin: 50% 50%;
		transition: opacity 0.25s linear, transform 0.25s ease-in-out;

		opacity: 0.4;

		cursor: pointer;

		.inner {
			margin: auto;
			width: 0.8rem;
			height: 0.8rem;
			border-radius: 50%;
			background-color: $brand-black;
			transition: background-color 0.25s linear;
		}

		&.responded {
			opacity: 1.0;
		}

		&.active {
			opacity: 1.0;
			transform: scale( 1.25 );
			
			.inner {
				background-color: $brand-white;
			}
		}
	}

	.nav-bar-wrap.beacon {

		.button-next {
			opacity: 0.0;
			pointer-events: none;
			touch-action: none;
		}

		.progress-module {
			opacity: 0.0;
			pointer-events: none;
			touch-action: none;
		}

	}

	.nav-bar-wrap.first-module {

		.button-back {
			opacity: 0.05;
			pointer-events: none;
			touch-action: none;
		}

	}

	.nav-bar-wrap.last-module {

		.button-next {
			opacity: 0.05;
			pointer-events: none;
			touch-action: none;
		}

	}




	// - - - QUESTION MODULES container

	.modules-wrap {

		position: relative;
		width: 100%;
		flex-grow: 1;

	}



	// - - - SUBMIT button

	.submit-button {

		position: fixed;
		bottom: 2rem;

		pointer-events: none;
		touch-action: none;

		cursor: pointer;

		border-radius: 50%;
		overflow: hidden;
		background-color: $brand-darkblue;

		display: block;
		opacity: 0;

		&.visible {
			opacity: 1;
		}


		.inner {
			position: relative;
		}

		.check {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 5rem;
			height: 5rem;

			svg {
				width: 2.35rem;
				height: 2.35rem;
				transform: translate( 0.05rem, 0.05rem );
			}

			svg .check-line {
				stroke: $brand-white;
				opacity: 0.2;
				transition: opacity 0.3s linear;
			}
		}

		&.active {
			pointer-events: initial;
			touch-action: initial;

			.check svg .check-line {
				opacity: 1.0;
			}
		}

	}



	// - - - LOCATIONS wrap

	.locations-wrap {

		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		color: $brand-darkgrey;

		pointer-events: none;
		touch-action: none;
		
		background-color: $brand-offwhite;
		perspective: -200px;

		&.active {
			pointer-events: inherit;
			touch-action: inherit;
		}

		.inner .global,
		.inner .local {
			display: none;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 6rem;

			background-color: $brand-offwhite;
			position: relative;

			width: 100%;
			max-width: 46rem;
    		margin: auto;
		}

		&.global .global {
			display: flex;
		}

		&.local .local {
			display: flex;
		}



		.location {
			font-size: 2.0em;
			width: 12rem;
			text-align: center;
			font-weight: 700;
			position: absolute;
			pointer-events: none;
			touch-action: none;
			letter-spacing: 0.1em;

			display: block;

			.label {

				width: 100%;
				position: relative;

				// not using dividing bars
				/*
				&::after {
					content: "";
					position: absolute;
					background-color: $brand-darkgrey;
					opacity: 0.2;
					width: 0.2rem;
					right: 0;
					height: 1.8rem;
					top: -0.6rem;
					// bottom: 0.8em;
				}
				*/

			}


		}

	}




	

}



@media screen and ( min-width: $breakpoint-1 ) {

	.section.poll {

		padding: 3rem;
		padding-bottom: 3rem;
		// padding-top: 7rem;
		// padding-bottom: 4rem;

		.basic-type {
			font-size: 1.25em;
		}
	}

}


@media screen and ( min-width: $breakpoint-2 ) {

	.section.poll {

		padding: 4rem;
		padding-bottom: 4rem;
		// padding-top: 7rem;
		// padding-bottom: 4rem;

		.col-small {
			max-width: $poll-col-small-max;
		}
		.col-large {
			max-width: $poll-col-large-max;
		}



	}

}


@media screen and ( min-width: $breakpoint-3 ) {

	.section.poll {

	}

}


@media screen and ( min-width: $breakpoint-4 ) {

	.section.poll {

	}

}


@media screen and ( min-width: $breakpoint-5 ) {

	.section.poll {

		.basic-type {
			font-size: 1.35em;
		}

		.button-back,
		.button-next {
			width: 7.5em;
		}

	}

}


@media screen and ( min-width: $breakpoint-6 ) {

	.section.poll {

	}

}


@media screen and ( min-width: $breakpoint-7 ) {

	.section.poll {

	}

}
