.section.poll .question-module.beacon {

	display: none;

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	flex-direction: column;
	justify-content: center;
	align-items: center;


	&.visible {
		display: flex;
	}

	.cta-wrap {

		position: absolute;
		transform: translateY( -17rem );
		
		// top: -0.2rem;

		// height: 4rem;
		// max-height: 4rem;
		// position: absolute;
		// top: -0%;
		// transform: translate( 0, -200% );
	}

	.cta-1 {
		font-size: 1.15rem;
		text-align: center;
		line-height: 1.15em;
		text-transform: uppercase;
		opacity: 0.7;
		@extend .col-small;
	}

	.cta-2 {
		font-size: 2.5rem;
		text-align: center;
		margin-top: 1rem;
		line-height: 1.15em;
		@extend .col-small;

		&.hidden {
			display: none;
		}
	}

	.beacon-wrap {
		width: 80%;
		margin: 3rem auto;
		padding: 2rem;

		@extend .col-small;	

		align-self: center;

	}


	.beacon-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.loc-pin-wrap {

		width: 6rem;
		position: absolute;

		.label {
			position: absolute;
			width: 6rem;
			text-align: center;
			transform: translateY( -1.8rem );
			font-size: 1.25rem;
		}

		.pin {
			width: 1rem;
			height: 1rem;
			min-width: 1rem;
			min-height: 1rem;
			border-radius: 50%;
			background-color: $brand-white;
			margin: auto;
		}

	}


	.link-wrap {

		width: 100%;

		position: absolute;
		bottom: 0;



		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

	}

	.link-inner {
		// position: absolute;
		// top: -0%;
		// transform: translate( 0, 200% );
		max-width: 24rem;
		border: solid 2px $brand-white;
		border-radius: 999px;
		font-weight: 700;
		// font-size: 1.15em;
		text-transform: uppercase;
		cursor: pointer;
		height: 4rem;
		flex-grow: 1;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 0.15em 1.25em 0.15em 1.5rem;
	
		&.hidden {
			display: none;
		}

		.fb-logo {
			width: 1.5rem;
			height: 1.5rem;
			min-height: unset;
			min-width: unset;
			
			svg {
				fill: $brand-white;
			}
		}

		.arrow {
			width: 1.0rem;
			height: 1.0rem;
			min-height: unset;
			min-width: unset;

			svg {
				fill: $brand-white;
			}
		}

		.label {
			flex-grow: 1;
			text-align: center;
		}

		.cta-button {
			font-size: 1.0rem;
			text-align: center;
		}

	}


	.list-link {

		margin-top: 4rem;
		font-size: 1.2rem;
		cursor: pointer;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;

		.label {
			margin-right: 0.8rem;
		}
		
		.arrow {
			width: 1em;
			height: 1em;
			min-width: 1em;
			min-height: 1em;

			svg { fill: $brand-white; }
		}


	}




}



@media screen and ( min-width: $breakpoint-1 ) {

	.section.poll .question-module.beacon {

	.cta-wrap {
			transform: translateY( -18rem );
		}

	}

}


@media screen and ( min-width: $breakpoint-2 ) {

	.section.poll .question-module.beacon {

		.beacon-wrap {
			width: 90%;
		}

		.cta-wrap {
			transform: translateY( -20rem );
		}

	}

}


@media screen and ( min-width: $breakpoint-3 ) {

	.section.poll .question-module.beacon {



	}

}


@media screen and ( min-width: $breakpoint-4 ) {

	.section.poll .question-module.beacon {

		// top: -1rem;

		.cta-wrap {
			transform: translateY( -22rem );
		}

		.cta-2 {
			margin-top: 2rem;
		}

	}

}


@media screen and ( min-width: $breakpoint-5 ) {

	.section.poll .question-module.beacon {

		.cta-wrap {
			transform: translateY( -27rem );
		}


	}

}


@media screen and ( min-width: $breakpoint-6 ) {

	.section.poll .question-module.beacon {



	}

}


@media screen and ( min-width: $breakpoint-7 ) {

	.section.poll .question-module.beacon {



	}

}





@media screen and ( min-width: $breakpoint-4 ) and ( max-height: $height-breakpoint ) {

	.section.poll .question-module.beacon {

		.beacon-wrap {
    		max-width: 28rem;
		}

		.list-link {
			margin-top: 2rem;
		}

		.cta-wrap {
			transform: translateY(-18rem);
		}

	}

}