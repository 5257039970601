// SITE

$site-width:  960px;
$site-gutter: 7.5%;

// BREAKPOINTS
$breakpoint-1: 400px;
$breakpoint-2: 520px;
$breakpoint-3: 640px;
$breakpoint-4: 760px;
$breakpoint-5: 880px;
$breakpoint-6: 1280px;
$breakpoint-7: 1600px;

$height-breakpoint: 720px;


// COLORS
$brand-black: 			#000;
$brand-white: 			#fff;

$brand-offblack: 		#1c2b33;
$brand-darkblue:	 	#152026;
$brand-darkgrey: 		#323232;
$brand-medgrey: 		#bbb;
$brand-offwhite: 		#cbd2d9;
$brand-slate: 			#69798b;

$brand-red: 			#E80B66;
$brand-green: 			#1FCC7A;
$brand-blue: 			#4B19D8;

$brand-salmon: 			#f5a490;



$brand-purple-dark:		#3e2d86;
$brand-purple-light:	#8e95ca;
$brand-blue-dark:		#005b94;
$brand-blue-light:		#5bc4f1;
$brand-green-dark:		#005a42;
$brand-green-light:		#4da458;
$brand-orange-dark:		#ca3112;
$brand-orange-light:	#f29111;
$brand-red-dark:		#790a1d;
$brand-red-light:		#dc3746;


$brand-bg-base: 		#1C2B33;
$brand-bg-purple: 		#34326b;
$brand-bg-blue: 		#0a5d87;
$brand-bg-green: 		#095942;
$brand-bg-orange: 		#9e4d38;
$brand-bg-red: 			#6d0f23;