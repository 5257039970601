$home-col-small-max: 24rem;
$home-col-large-max: 56rem;

.section.home {

	padding: 2rem;

	.col-small {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.col-large {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.basic-type {
		font-size: 1.05em;
	}

	.basic-type-large {
		font-size: 1.5em;
	}

	.inner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		flex-grow: 1;
		width: 100%;
	}

	.block-1, .block-2, .block-3 {
		display: none;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		flex-grow: 1;
		width: 100%;

		&.active {
			display: flex;
		}
	}

	.logo-wrap {
		margin-bottom: auto;
	}

	.logo {
		margin-top: 4rem;
		margin-left: auto;
		margin-right: auto;
		width: 35vw;
		max-width: 12rem;

		svg { 
			width: 100%;
			height: auto;
		}
	}

	.welcome {
		@extend .col-large;

		padding-top: 2rem;
		padding-bottom: 2rem;
		margin-bottom: auto;
		margin-top: auto;
		font-weight: 500;
		color: $brand-white;
		font-size: 2.4em;
		line-height: 1.15em;
	}

	.location-select-label {
		// @extend .col-large;
		margin: auto;
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-size: 1.35em;
		line-height: 1.25em;
		color: $brand-offwhite;
		max-width: 32rem;
	}

	.location-select-wrap {

		@extend .col-small;

		margin-top: 1rem;
		margin-bottom: 1rem;
		// padding-right: 3em;
		background-color: $brand-white;
		border: solid 2px $brand-white;
		border-radius: 999px;
		font-weight: 700;
		// padding: 0.15em 1em 0.15em 1.5rem;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		@extend .basic-type;

		select {
			flex-grow: 1;
			border:none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			-ms-appearance: none;
		}

		.button-arrow {
			transform: rotate( 90deg );
			transform-origin: 50% 50%;
			width: 1.1rem;
			height: 1.1rem;
			min-width: 1.1rem;
			min-height: 1.1rem;
			max-width: 1.1rem;
			max-height: 1.1rem;
			margin-right: 0.4rem;
			position: absolute;
			right: 1rem;
			pointer-events: none;
			touch-action: none;
		}

		.button-arrow svg {
			fill: $brand-black;
		}

		option {
			cursor: pointer;
			padding: 0em;
			color: black;
			background-color: $brand-white;
		}
	}

	.location-select {
		cursor: pointer;
		width: 100%;
		padding: 1.25rem 1rem 1.25rem 2.0rem;
		// padding: 0.5em;
		// padding-right: 0;
		outline: none;
		border: none;
		background-color: rgba( 0,0,0,0 );
	}

	.location-select-wrap {
		position: relative;
	}

	.location-select-wrap.valid {

		
		border: solid 2px $brand-slate;
		background-color: rgba( 0,0,0,0 );

		.location-select {
			color: $brand-white;
		}

		.button-arrow {
			pointer-events: none;
			touch-action: none;
			position: absolute;
			right: 1rem;
		}

		.button-arrow svg {
			fill: $brand-white;
		}

		&:focus {
			padding: 1em;
			color: $brand-offblack;
			background-color: $brand-white;
		}

	}

	.begin-wrap {
		@extend .col-small;
		margin-top: auto;
	}

	.begin-button {

		@extend .basic-type;
		opacity: 0.2;
		pointer-events: none;
		touch-action: none;
		cursor: not-allowed;
		border: solid 1px $brand-white;
		color: $brand-white;
		width: 100%;
		padding: 1em;
		text-transform: uppercase;
		border-radius: 0.25em;
		font-weight: 700;

		&.ready {
			opacity: 1.0;
			pointer-events: initial;
			touch-action: initial;
			cursor: pointer;
			color: $brand-offblack;
			border: solid 1px $brand-salmon;
			background-color: $brand-salmon;
		}

	}

	.back-wrap {
		width: 100%;
		@extend .col-small;
		margin-top: 1rem;
		opacity: 0.8;
	}

	.back-button {
		pointer-events: initial;
		touch-action: initial;
		cursor: pointer;
		color: $brand-white;
		width: 100%;
		padding: 1em;
		text-transform: uppercase;
		font-weight: 700;

	    max-width: 16rem;
    	margin: auto;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.button-arrow {
			@extend .basic-type;
			width: 0.65em;
			margin-right: 0.75em;
			margin-top: 0.025em;
		}


		.button-arrow svg {
			width: 100%;
			height: auto;
			fill: $brand-white;
			transform: rotate( 180deg );
		}
		
		.button-label {
			@extend .basic-type;

		}

	}


	.list-wrap {

		@extend .col-small;
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		text-align: center;
		margin-top: 1rem;
		margin-bottom: auto;

		text-align: left;

		overflow-y: scroll !important;
		max-height: 24rem;
		touch-action: pan-y !important;
		-webkit-overflow-scrolling: touch;
		pointer-events: initial;

	}

	// .list-wrap-inner {
		// padding: 2rem 0;
	// }

	.list-cta {
		@extend .col-small;
		@extend .basic-type-large;
		font-weight: 500;
		line-height: 1.15em;
		width: 100%;
		margin-top: auto;
		text-align: left;

		border-bottom: solid 1px $brand-white;
		padding-bottom: 0.8em;
		margin-bottom: 0.8em;
	}

	.list-label {
		margin-top: auto;
		@extend .col-small;
		width: 100%;
		opacity: 0.5;
	}

	.empty-list {

		text-align: center;

		h4 {
			@extend .basic-type-large;
			font-weight: 500;
			line-height: 1.15em;
			width: 100%;
			margin-top: 1.2em;
			margin-bottom: 0.8em;
		}

		p {
			@extend .basic-type;
			font-weight: 500;
			line-height: 1.15em;
		}

	}

	.poll-link {

		&:first-child {
			margin-top: 1rem;
		}

		@extend .basic-type-large;
		font-weight: 500;
		line-height: 1.15em;

		height: 2.5em;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.value {
			margin-bottom: 0.1rem;
			opacity: 0.3;
		}

		.ring {
			min-width: 2rem;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			border: solid 2px rgba( 255,255,255,0.3 );

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			margin-right: 0.75em;
		}

		.dot {
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 50%;
			background-color: $brand-white;

			display: none;
		}

		&.active {
			
			.value {
				opacity: 1.0;
			}

			.dot {
				opacity: 1.0;
				display: block;
			}
		}

	}


	.begin-wrap-alt {
		@extend .col-small;
		margin-top: auto;
	}

	.begin-button-alt {

		@extend .basic-type;
		opacity: 0.2;
		pointer-events: none;
		touch-action: none;
		cursor: not-allowed;
		border: solid 1px $brand-white;
		color: $brand-white;
		width: 100%;
		padding: 1em;
		text-transform: uppercase;
		border-radius: 0.25em;
		font-weight: 700;

		&.ready {
			opacity: 1.0;
			pointer-events: initial;
			touch-action: initial;
			cursor: pointer;
			color: $brand-offblack;
			border: solid 1px $brand-salmon;
			background-color: $brand-salmon;
		}

	}



	.entry-wrap {
	
		@extend .col-small;

		margin-top: 2rem;
	}

	.entry-wrap input {

		line-height: 1.4em;
		border-radius: 0.5rem;
		text-align: center;
		letter-spacing: 0.1em;
		font-weight: 700;
		width: 100%;
		font-size: 2.5em;
		padding: 1rem;
		background-color: rgba( 255,255,255, 0.8 );

		color: $brand-darkgrey;

		

		&.invalid {
			color: $brand-red;
			background-color: rgba( 40,40,40, 0.8 );
		}

		&.valid {
			color: $brand-green;
			background-color: rgba( 40,40,40, 0.8 );
		}


	}









}


@media screen and ( min-width: $breakpoint-1 ) {

	.section.home {

		padding: 3rem;

		.basic-type {
			font-size: 1.25em;
		}

		.welcome {
			font-size: 2.9em;
		}

		.back-button {
 			max-width: 20rem;
 		}

	}

}


@media screen and ( min-width: $breakpoint-2 ) {

	.section.home {

		padding: 4rem;

		.col-small {
			max-width: $home-col-small-max;
		}
		.col-large {
			max-width: $home-col-large-max;
		}

		.welcome {
			font-size: 3.2em;
		}

		.back-button {
 			max-width: 20rem;
 		}

	}

}


@media screen and ( min-width: $breakpoint-3 ) {

	.section.home {

	}

}


@media screen and ( min-width: $breakpoint-4 ) {

	.section.home {

	}

}


@media screen and ( min-width: $breakpoint-5 ) {

	.section.home {


		.basic-type {
			font-size: 1.35em;
		}

		.location-select-label {
			font-size: 1.5em;
		}

	}

}


@media screen and ( min-width: $breakpoint-6 ) {

	.section.home {

	}

}


@media screen and ( min-width: $breakpoint-7 ) {

	.section.home {

	}

}


@media screen and ( min-width: $breakpoint-4 ) and ( max-height: $height-breakpoint ) {

	.section.home {
		// background-color: red;
		// display: none;

		



	}

}