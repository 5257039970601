$poll-col-small-max: 36rem;
$poll-col-large-max: 64rem;

.section.poll .question-module {

	display: none;

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	flex-direction: column;
	justify-content: center;
	align-items: center;


	&:not( .beacon ) {
		padding-top: 4rem;
		bottom: 4rem;
	}

	&.visible {
		display: flex;
	}

	&>.inner {

		position: relative;
		width: 100%;
		flex-grow: 1;

		// overflow-x: hidden;
		// overflow-y: hidden;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}



	.response-wrap {

		@extend .col-small;

		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&.visible {
			display: flex;
		}

	}

	.loc-eyebrow {

		align-self: flex-start;
		margin-bottom: 3rem;
		font-size: 1.25rem;
		opacity: 0.5;

	}

	.question {

		font-size: 2.8em;
		font-weight: 500;
		line-height: 1.2em;
		text-align: left;
		width: 100%;

		padding-bottom: 3rem;

		@extend .col-small;

	}

	.divider {
		width: 100%;
		margin-bottom: 3rem;
		height: 0.2rem;
		background-color: $brand-white;
	}

	.submit-wrap {

		position: absolute;
		top: 0;
		left: 1rem;
		bottom: 0;
		right: 1rem;

		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		pointer-events: none;
		touch-action: none;

		&.visible {
			display: flex;
		}

	}


	.submit-label {

		font-size: 1.5em;
		overflow: hidden;
		margin-bottom: 2rem;
		line-height: 1.5em;

		.inner {

		}
	}

	.submit-value {

		font-size: 3.5em;
		line-height: 1.15em;
		overflow: hidden;
		margin-bottom: 8rem;
		line-height: 1.5em;

		.inner {

		}
	}










	.data-wrap {

		position: absolute;
		top: 0;
		left: 1rem;
		bottom: 0;
		right: 1rem;

		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		pointer-events: none;
		touch-action: none;

		&.visible {
			display: flex;
		}

	}


	.question-small {
		font-size: 1.25rem;
		line-height: 1.2em;
		position: absolute;
		top: 0.5rem;
		width: 100%;
		text-align: center;

		opacity: 0.0;


		.q-label {
			opacity: 0.5
		}

		.a-wrap {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: baseline;

			margin-top: 0.5rem;
		}

		.a-label {

			opacity: 0.5;
			margin-right: 0.5em;

			&::after {
				content: ":",

			}
		}

		.a-value {
			opacity: 1.0;
		}

	}

	.early-responder {
		display: none;

	}

	&.show-early-response {

		.early-responder {

			display: flex;

		}

	}












	&.multiplechoice {

		.divider {
			margin-bottom: 1rem;
		}

		.response-wrap {
			// padding: 10vh 0;
			padding-bottom: 2rem;
			padding-top: 1rem;
		}

		.response-list {
			@extend .col-small;
			width: 100%;

			cursor: grab;
			overflow: hidden;
			flex-grow: 1;
			max-height: 25rem;
			// margin-bottom: 8rem;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			perspective: 100%;
		}

		.response-option {
			width: 75%;
			padding: 0.6em 0;
			line-height: 1.15em;
			font-size: 2.0em;
			font-weight: 500;
			text-align: center;

			position: absolute;

			pointer-events: none;
			touch-action: none;
			opacity: 0.2;


			&.selected {
				opacity: 1.0;
			}
		}

		.data-block {
			@extend .col-small;
			// @extend .row-small;
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			// flex-grow: 1;

		}

		.data-block-inner {
			width: 70%;
			padding-bottom: 80%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			// flex-grow: 1;
		}

		.ring-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			svg {
				width: 70%;
				height: auto;
				margin: auto;
				display: block;
			}
		}

		.labels-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.total-count {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			div:first-child {
				font-size: 2em;
				margin-bottom: 0.8rem;
			}

			div:last-child {
				font-size: 0.9em;
			}
		}


		.labels-group {

		}

		.label {
			opacity: 0;
			position: absolute;
			top: 0;
			left: -2rem;
			width: 4rem;
			height: 50%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin-left: 50%;
			padding-top: 2rem;
			font-size: 1.2em;
		}

		.key-wrap {
			@extend .col-small;

			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 0 3rem;
			margin-top: 1.5rem;
			// margin-bottom: 8rem;
		}

		.key-module {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			width: calc( 50% - 1rem );
			margin-bottom: 1.2rem;
			margin-right: 1rem;
		}

		.key-color {
			width: 1.25em;
			min-width: 1.25em;
			height: 1.25em;
			min-height: 1.25em;
			border-radius: 50%;
			overflow: hidden;
			background-color: black;
			margin-right: 1rem;
		}

		.key-value {
			font-size: 1.2rem;			line-height: 1.2em;
		}

		.early-responder {
			position: absolute;
			width: 46%;
			text-align: center;
			font-size: 1.0rem;
			line-height: 1.2em;
			opacity: 0.5;
		}

		&.show-early-response .total-count {
			display: none;
		}


	}

	&.range {

		.response-slider {
			width: 100%;
			// margin-bottom: 8rem;
		}

		.slider-wrap-outer {
			width: calc( 100% - 4rem );
			// padding-left: 2rem;
			// padding-right: 2rem;
			position: relative;

		}

		.slider-wrap {

			width: 100%;
			height: 2rem;
			position: relative;
			padding-top: 6rem;
			padding-bottom: 2rem;

			.bg,
			.fill {
				// top: 0;
				bottom: 2rem;
				left: 0;
				right: 0;
				height: 1rem;
				position: absolute;
			}

			.bg {
				background-color: $brand-white;
				opacity: 0.25;
			}

			.fill {
				transform-origin: 0% 50%;
				transform: scaleX( 0.5 );
				background-color: $brand-medgrey;
				opacity: 1.0;
			}

			.handle {
				box-sizing: border-box;
				position: absolute;
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				bottom: 1rem;
				left: -1.5rem;
				transform: translateX( 0 );
				border: $brand-medgrey solid 0.5rem;
				background-color: $brand-white;
			}

			.response-label {
				font-size: 2em;
				font-weight: 700;
				pointer-events: none;
				touch-action: none;

				position: absolute;
				width: 8rem;
				height: 3rem;
				top: 1rem;
				left: -4rem;
				text-align: center;
			}
		}

		.bounds-wrap {

			width: calc( 100% - 4rem );

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.bound {
				font-size: 1.5em;
			}
		}


		.data-block {
			@extend .col-large;
			flex-grow: 1;
			// margin-top: 0;
			// margin-bottom: 8rem;
			margin: 7rem 0 5rem;
			position: relative;
			display: flex;
			text-align: center;
			justify-content: flex-end;
			flex-direction: column;
			align-items: center;
		}

		&.show-early-response {
			.data-block {
				margin: 6rem 0 6rem;
			}
		}

		.bars-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.bar-module {
			flex-grow: 1;
			background-color: rgba( 0,0,0,0.15);
			width: 100%;
			margin: 0.5rem 0;
			position: relative;
			min-height: 1.0rem;
			max-height: 3rem;
		}

		.bar-label {
			position: absolute;
			// height: 1rem;
			// top: -1.5rem;
			left: 0;
			top: 0;
			bottom: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-left: 0.8rem;
		}

		.bar-data {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #000000;
		}


		.labels-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.labels-group {
			position: absolute;
			top: 0;
			left:0 ;
			right: 0;
			bottom: 0;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.label {
			opacity: 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-grow: 1;
			margin: 0.5rem 0;
			min-height: 1.0rem;
			max-height: 3rem;
		}

		.early-responder {
			font-size: 1.2rem;
			line-height: 1.2em;
			position: absolute;
			bottom: 1.5rem;
			text-align: center;
			opacity: 0.5;

		}

	}


	&.binary {

		.response-wrap {
			padding-bottom: 8rem;
		}

		.response-list {
			@extend .col-small;
			width: 100%;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

		}

		.data-wrap {
			flex-grow: 1;
		}

		.response-label {
			width: 100%;
			max-width: 12rem;
			padding: 0.6em 0;
			line-height: 1.15em;
			font-size: 2.0rem;
			font-weight: 500;
			text-align: center;

			transition: opacity 0.15s linear, transform 0.15s ease-in-out;
			transform: scale( 0.8 );

			// position: absolute;
			display: inline-block;

			&:nth-child( 1 ) {
				// align-self: flex-start;
				left: 0;
				padding-right: 1rem;
				text-align: left;
				transform-origin: "0% 50%";			
			}

			&:nth-child( 2 ) {
				// align-self: flex-end;
				right: 0;
				padding-left: 1rem;
				text-align: right;
				transform-origin: "100% 50%";
			}

			cursor: pointer;

			opacity: 0.2;

			&.selected {
				opacity: 1.0;
				transform: scale( 1.0 );
			}
		}

		.slider-wrap {

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			position: absolute;
			padding: 2rem 0;

			left: calc( 50% - 6.0rem );

			.inner {
				position: relative;
				width: 12rem;
				height: 3.0rem;
			}

			.bg {
				border-radius: 999px;
				// background-color: $brand-medgrey;
				background-color: rgba( 255,255,255,0.2 );
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
			
			.handle {
				// box-sizing: border-box;
				position: absolute;
				transform: translate( 0 );
				width: 4.0rem;
				height: 4.0rem;
				border-radius: 100%;
				background-color: $brand-white;
				bottom: -0.5rem;
				left: -0.5rem;
				transform: translateX( 2.5rem );
				border: $brand-medgrey solid 0.5rem;
			}

		}

		.labels-wrap {
			position: absolute;
			width: 100%;
			text-align: center;

			@extend .col-large;
		}

		.same-blurb,
		.oppo-blurb {

			position: absolute;
			width: 100%;

			font-size: 3.0em;

			margin-left: auto;
			margin-right: auto;
			line-height: 1.15em;

			&>.inner {
				@extend .col-small;
			}

			span {

			}

			.value {

			}

			.location {

			}

			.response {

			}

		}

		.same-blurb {
			top: -3rem;
			transform: translate( 0, -100% );
		}

		.oppo-blurb {
			bottom: -3rem;
			font-size: 1.5em;
			transform: translate( 0, 100% );
		} 

		.data-block {
			width: 100%;

			@extend .col-large;

			height: 6rem;
			background-color: rgba( 0,0,0,0.2 );
			position: relative;
		}
		
		.data-bar {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #000000;
		}

		.labels-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 6rem;
			padding: 0 1rem;
		}

		.label {
			font-size: 2.0em;
		}

		.label.same {
			text-align: left;
		}

		.label.oppo {
			text-align: right;
		}


		.early-responder {
			font-size: 1.2rem;
			line-height: 1.2em;
			// margin-top: auto;
			position: absolute;
			bottom: 2rem;
			opacity: 0.5;
			
			@extend .col-small;
			text-align: center;
		}




	}



}





@media screen and ( min-width: $breakpoint-1 ) {

	.section.poll .question-module {

		&.multiplechoice {
			.data-block-inner {
				width: 75%;
				padding-bottom: 80%;
			}
		}

	}

}


@media screen and ( min-width: $breakpoint-2 ) {

	.section.poll .question-module {

		.col-small {
			max-width: $poll-col-small-max;
		}
		.row-small {
			height: $poll-col-small-max;
		}
		.col-large {
			max-width: $poll-col-large-max;
		}

		&.range {
			.slider-wrap-outer {
				width: 100%;
			}
			.bounds-wrap {
				width: 100%;
			}
		}

		&.multiplechoice {
			.data-block-inner {
				width: 100%;
				padding-bottom: 100%;
			}

			.early-responder {
				font-size: 1.25em;
				width: 40%;
			}
		}

		.question-small {
			font-size: 1.3rem;
		}

	}

}


@media screen and ( min-width: $breakpoint-3 ) {

	.section.poll .question-module {



	}

}


@media screen and ( min-width: $breakpoint-4 ) {

	.section.poll .question-module {

		.question-small {
			font-size: 1.35rem;
		}

		&.multiplechoice {

			.data-wrap {
				flex-direction: row;

			}

			.key-wrap {
				margin: 0;
			}

			.data-block {
				margin: 0;
				// padding-bottom: 30%;
			}
		}

	}

}


@media screen and ( min-width: $breakpoint-5 ) {

	.section.poll .question-module {


	}

}


@media screen and ( min-width: $breakpoint-6 ) {

	.section.poll .question-module {

	}

}


@media screen and ( min-width: $breakpoint-7 ) {

	.section.poll .question-module {

	}

}






@media screen and ( min-width: $breakpoint-4 ) and ( max-height: $height-breakpoint ) {

	.section.poll .question-module {
		// background-color: red;
		// display: none;

		font-size: 0.7em;

		.question-small {
			top: -1.0rem;
		}

		&.multiplechoice {
			.data-block,
			.key-wrap {
				margin-top: 4rem;
			}
			.data-block-inner {
				width: 75%;
				padding-bottom: 80%;
			}
		}

		&.binary {
			.data-block {
				height: 4rem;
			}
			.same-blurb {
				top: -1rem;
			}
			.oppo-blurb {
				bottom: -1rem;
			}
		}

		&.range {
			.bar-module,
			.label {
				margin: 0.25rem 0;
				min-height: 1.5rem;
				font-size: 1rem;
			}
		}

	}

}