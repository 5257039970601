.stack-wrap>.world-wrap {

	// background-color: #1c2b33;
	background-image: url('/assets/images/earth.png');
	background-repeat: repeat;
	background-size: 100%;
	background-position: 0px 0px;

	mix-blend-mode: multiply;
	// opacity: 0.2;

}